import { element } from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import NoConnection from './NoConnection';
import Waiting from './Waiting';
const Authorization = ({ children }) => {
	const { login, status } = useSelector(state => state);
	return status === 'LOADING' ? (
		<Waiting />
	) : status === 'SUCCESS' ? (
		login ? (
			<Navigate replace to='/profile' />
		) : (
			children
		)
	) : (
		<NoConnection />
	);
};
Authorization.defaultProps = {
	children: <>No Content</>,
};
Authorization.propTypes = {
	children: element,
};
export default Authorization;
