import isObject from 'lodash/isObject';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Slide, toast, ToastContainer } from 'react-toastify';
import Loading from './Loading';
import Pages from './Pages';
import { setUser } from './Redux';
import Axios from './Services/Axios';
import { reactToastifyConfiguration } from './Services/Configurations';
const App = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'app' });
	const dispatch = useDispatch();
	const { loading } = useSelector(state => state);
	useEffect(() => {
		const getUser = async () => {
			await dispatch(setUser({ status: 'LOADING', user: {}, login: false }));
			const token = localStorage.getItem('token');
			if (token) {
				Axios()
					.get('/get_user')
					.then(response => {
						const status = response?.data?.status;
						const data = response?.data?.data;
						const user = isObject(data) ? data : {};
						if (status === 1) {
							dispatch(
								setUser({ user, login: true, status: 'SUCCESS' })
							);
						} else {
							dispatch(
								setUser({ user: {}, login: false, status: 'SUCCESS' })
							);
							localStorage.removeItem('token');
						}
					})
					.catch(e => {
						const toJSON = e?.toJSON;
						if (typeof toJSON === 'function') {
							const status = e?.toJSON()?.status;
							if (status === 401) {
								localStorage.removeItem('token');
								dispatch(
									setUser({
										user: {},
										login: false,
										status: 'SUCCESS',
									})
								);
							} else {
								dispatch(
									setUser({
										user: {},
										login: false,
										status: 'NO_CONNECTION',
									})
								);
							}
						} else {
							dispatch(
								setUser({
									user: {},
									login: false,
									status: 'NO_CONNECTION',
								})
							);
						}
					});
			} else {
				dispatch(setUser({ user: {}, login: false, status: 'SUCCESS' }));
			}
		};
		getUser();
	}, [dispatch]);
	useEffect(() => {
		console.log(
			`%c${t('note')}`,
			'color: #f1c40f; font-size: 36px; font-weight: 600; font-family: Gilroy, sans-serif'
		);
		window.addEventListener('offline', () => toast.warning(t('offline')));
		window.addEventListener('online', () => toast.info(t('online')));
	}, [t]);
	return (
		<>
			<Suspense fallback={<></>}>
				<Pages />
			</Suspense>
			<section>
				<ToastContainer
					{...reactToastifyConfiguration}
					transition={Slide}
				/>
			</section>
			{loading && <Loading />}
		</>
	);
};
export default App;
