import styled, { keyframes } from 'styled-components';
const animation = keyframes`
	0% {
		left: 0;
		width: 0;
	}
	50% {
		left: 0;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 0;
	}
`;
const StyledElement = styled.section`
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	width: 100%;
	& div {
		background: var(--light-brown);
		border-radius: 3px;
		height: 6px;
		max-width: 100%;
		position: relative;
		width: 450px;
		&:before {
			animation-delay: 0;
			animation-duration: 700ms;
			animation-iteration-count: infinite;
			animation-name: ${animation};
			animation-timing-function: linear;
			background: var(--blue);
			border-radius: 3px;
			content: '';
			height: 100%;
			position: absolute;
			top: 0;
		}
	}
`;
const Waiting = () => (
	<StyledElement className='container'>
		<div></div>
	</StyledElement>
);
export default Waiting;
