import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DownArrow from '../../Images/down.svg';
import Russian from '../../Images/russian.svg';
import Uzbekistan from '../../Images/uzbekistan.svg';
const useDetect = (ref, state) => {
	const [active, setActive] = useState(state);
	useEffect(() => {
		const onClick = () => {
			if (ref.current !== null) {
				setActive(!active);
			}
		};
		if (active) {
			window.addEventListener('click', onClick);
		}
		return () => {
			window.removeEventListener('click', onClick);
		};
	}, [active, ref]);
	return [active, setActive];
};
const Container = styled.div`
	border-left: 1px solid #e0e4ea;
	height: 100%;
	position: relative;
	& button {
		align-items: center;
		background: transparent;
		border: none;
		display: flex;
		font-size: 14px;
		font-weight: 700;
		height: 100%;
		margin: 0;
		padding: 0 0 0 24px;
		transition: box-shadow 0.4s ease;
		vertical-align: middle;
		& .flag {
			height: 18px;
			margin: 0 7px 0 0;
			width: 18px;
			&[data-active='active'] {
				display: inline-block;
			}
			&[data-active='inactive'] {
				display: none;
			}
		}
		& span {
			white-space: nowrap;
		}
		& .down {
			height: 7px;
			margin: 0 0 0 7px;
			width: 10px;
		}
	}
	& ul {
		background: #ffffff;
		border-radius: 8px;
		box-shadow: 0 1px 8px rgba(110, 120, 146, 0.5);
		list-style: none;
		margin: 0;
		opacity: ${({ isActive }) => (isActive ? 1 : 0)};
		overflow: hidden;
		padding: 0;
		position: absolute;
		right: 0;
		top: 80px;
		transform: translateY(${({ isActive }) => (isActive ? '0' : '-20px')});
		transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
		visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
		& li {
			border-bottom: 1px solid rgba(110, 120, 146, 0.15);
			cursor: pointer;
			font-size: 15px;
			font-weight: 600;
			padding: 13px 18px;
			transition: 0.4s;
			white-space: nowrap;
			&[data-active='active'] {
				background: rgba(110, 120, 146, 0.15);
			}
			&[data-active='inactive'] {
				background: transparent;
			}
			&:hover {
				background: rgba(110, 120, 146, 0.15);
			}
			& p {
				height: 100%;
				margin: 0;
				width: 100%;
				& img {
					height: 18px;
					margin: 0 7px -3px 0;
					width: 18px;
				}
			}
		}
	}
`;
const Language = () => {
	const ref = useRef(null);
	const { i18n } = useTranslation();
	const { language } = i18n;
	const [active, setActive] = useDetect(ref, false);
	const changeLanguage = (e, language) => {
		e.stopPropagation();
		i18n.changeLanguage(language);
		setActive(!active);
	};
	return (
		<Container isActive={active}>
			<button onClick={() => setActive(!active)}>
				<img
					alt='uzbekistan-flag'
					className='flag'
					data-active={language === 'uz' ? 'active' : 'inactive'}
					src={Uzbekistan}
				/>
				<img
					alt='russian-flag'
					className='flag'
					data-active={language === 'ru' ? 'active' : 'inactive'}
					src={Russian}
				/>
				<span>
					{language === 'ru'
						? 'Русский'
						: language === 'uz'
						? 'Oʻzbek tili'
						: ''}
				</span>
				<img className='down' alt='open-languages' src={DownArrow} />
			</button>
			<ul ref={ref}>
				<li
					data-active={language === 'uz' ? 'active' : 'inactive'}
					onClick={e => changeLanguage(e, 'uz')}
				>
					<p>
						<img alt='uzbekistan-flag' src={Uzbekistan} />
						<span>Oʻzbek tili</span>
					</p>
				</li>
				<li
					data-active={language === 'ru' ? 'active' : 'inactive'}
					onClick={e => changeLanguage(e, 'ru')}
				>
					<p>
						<img alt='russian-flag' src={Russian} />
						<span>Русский</span>
					</p>
				</li>
			</ul>
		</Container>
	);
};
export default Language;
