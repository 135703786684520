import { LOADING, SET_USER } from './types';
import {get} from 'lodash';

const initialState = {
	loading: false,
	login: false,
	status: 'LOADING',
	user: {
		avatar: '',
		balance: 0,
		birth_date: '',
		first_name: '',
		gender: '',
		id: '',
		last_name: '',
		login: '',
		region: {
			id: '',
			name_uz: '',
			name_ru: '',
		},
	},
	refrashe: true,
	rooms: {},
	open_room: 0,
    messages: {}
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING:
			return {
				...state,
				loading: action.loading,
			};
		case SET_USER:
			return {
				...state,
				login: action.login,
				user: action.user,
				status: action.status,
			};
		case 'REFRASHE':{
			return{
				...state,
				refrashe: !state.refrashe
			}
		}

		case 'SET_ROOMS':
			if(action.more)
				return{
					...state,
					rooms: {...get(action, 'payload', {}), results: [...get(state, 'rooms.results', []),  
																		...get(action, 'payload.results', [])]}
				}
			return{
				...state,
				rooms: get(action, 'payload', {})
			}
		case 'SET_ROOM':{
			const cl = state.rooms;
			let clr = [], t=true;
			cl.results.forEach(item => {
				if(item.id===get(action, 'payload.id', 0)){ 
					t = false; 
					clr.push({ ...item, ...get(action, 'payload', {}) });
				}
				else clr.push(item);
			});
			if(t) clr =  [get(action, 'payload', {}), ...clr];
			return{
				...state,
				rooms: {...cl, results: clr}
			}
		}
		case 'DEL_ROOM':{
			const cl = state.rooms;
			let clr = []
			cl.results.forEach(item => {
				if(item.id!==get(action, 'payload', 0)) clr.push(item);
			});
			return{
				...state,
				rooms: {...cl, results: clr}
			}
		}

		case 'SET_MSGS':
			console.log('action=>', action);
			if(action.more)
				return{
					...state,
					messages: {...get(action, 'payload', {}), results: [...get(state, 'messages.results', []),  
																		...get(action, 'payload.results', [])]},
					open_room: get(action, 'room_id', 0)
				}
			return{
				...state,
				open_room: get(action, 'room_id', 0),
				messages: get(action, 'payload', {})
			}
		case 'CLOSE_ROOM':
			return{
				...state,
				open_room: 0
			}
		case 'SET_MSG':{
			const cl = state?.messages;
			let clr = [], t=true;
			get(cl, 'results', []).forEach(item => {
				if(item.id===get(action, 'payload.id', 0)){ 
					t = false; 
					clr.push({ ...item, ...get(action, 'payload', {}) });
				}
				else clr.push(item);
			});
			if(t) clr =  [get(action, 'payload', {}), ...clr];
			return{
				...state,
				messages: {...cl, results: clr}
			}
		}
		case 'DEL_MSG':{
			const cl = state.messages;
			let clr = []
			get(cl, 'results', []).forEach(item => {
				if(item.id!==get(action, 'payload', 0)) clr.push(item);
			});
			return{
				...state,
				messages: {...cl, results: clr}
			}
		}

		default:
			return state;
	}
};
export default reducer;
