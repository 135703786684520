import { LOADING, SET_USER } from './types';
export const loading = loading => ({
	loading,
	type: LOADING,
});
export const setUser = ({ user, login, status }) => ({
	login,
	status,
	type: SET_USER,
	user,
});
