import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Admin from '../Controller/Admin';
import Authorization from '../Controller/Authorization';
import Teacher from '../Controller/Teacher';
const ResetPassword = lazy(() => import('./Register/ResetPassword'));
const SignIn = lazy(() => import('./Register/SignIn'));
const CourseBuy = lazy(() => import('./Admin/CourseBuy'));
const CourseCreate = lazy(() => import('./Teacher/CourseCreate'));
const CourseEdit = lazy(() => import('./Teacher/CourseEdit'));
const CourseSections = lazy(() => import('./Teacher/CourseSections'));
const Courses = lazy(() => import('./Teacher/Courses'));
const Exam = lazy(() => import('./Teacher/Exam'));
const Exams = lazy(() => import('./Teacher/Exams'));
const LessonEdit = lazy(() => import('./Teacher/LessonEdit'));
const NotFound = lazy(() => import('./Public/NotFound'));
const Profile = lazy(() => import('./Teacher/Profile'));
const Statistics = lazy(() => import('./Teacher/Statistics'));
const Settings = lazy(() => import('./Teacher/Settings'));
const Tasks = lazy(() => import('./Teacher/Tasks'));
const Chat = lazy(() => import('./Chat/Chat'));
const Pages = () => (
	<Routes>
		<Route path='/'>
			<Route
				index
				element={
					<Authorization>
						<SignIn />
					</Authorization>
				}
			/>
			<Route path='reset-password'>
				<Route
					index
					element={
						<Authorization>
							<ResetPassword />
						</Authorization>
					}
				/>
			</Route>
			
			<Route path='profile'>
				<Route
					index
					element={
						<Teacher>
							<Profile />
						</Teacher>
					}
				/>
			</Route>
			<Route path='statistic'>
				<Route
					index
					element={
						<Teacher>
							<Statistics />
						</Teacher>
					}
				/>
			</Route>
			<Route path='settings'>
				<Route
					index
					element={
						<Teacher>
							<Settings />
						</Teacher>
					}
				/>
			</Route>
			<Route path='exams'>
				<Route
					index
					element={
						<Teacher>
							<Exams />
						</Teacher>
					}
				/>
				<Route path=':courseId'>
					<Route
						index
						element={
							<Teacher>
								<Exam />
							</Teacher>
						}
					/>
				</Route>
			</Route>
			<Route path='tasks'>
				<Route
					index
					element={
						<Teacher>
							<Tasks />
						</Teacher>
					}
				/>
			</Route>
			<Route path='courses'>
				<Route
					index
					element={
						<Teacher>
							<Courses />
						</Teacher>
					}
				/>
				<Route path='create'>
					<Route
						index
						element={
							<Teacher>
								<CourseCreate />
							</Teacher>
						}
					/>
				</Route>
				<Route path='edit'>
					<Route path=':courseId'>
						<Route
							index
							element={
								<Teacher>
									<CourseEdit />
								</Teacher>
							}
						/>
						<Route path='sections'>
							<Route
								index
								element={
									<Teacher>
										<CourseSections />
									</Teacher>
								}
							/>
							<Route path=':lessonId'>
								<Route
									index
									element={
										<Teacher>
											<LessonEdit />
										</Teacher>
									}
								/>
							</Route>
						</Route>
					</Route>
				</Route>
			</Route>
			<Route path='chat'>
				<Route
					index
					element={
						<Teacher>
							<Chat />
						</Teacher>
					}
				/>
				<Route path=':room_id'>
					<Route
						index
						element={
							<Teacher>
								<Chat />
							</Teacher>
						}
					/>
				</Route>
			</Route>
			<Route path='user-buys'>
				<Route
					index
					element={
						<Admin>
							<CourseBuy />
						</Admin>
					}
				/>
			</Route>
			<Route path='*' element={<NotFound />} />
		</Route>
	</Routes>
);
export default Pages;
