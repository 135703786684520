import { element } from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from './Layout';
import NoConnection from './NoConnection';
import Waiting from './Waiting';
const Teacher = ({ children }) => {
	const { login, status } = useSelector(state => state);
	return status === 'LOADING' ? (
		<Waiting />
	) : status === 'SUCCESS' ? (
		login ? (
			<Layout>{children}</Layout>
		) : (
			<Navigate replace to='/' />
		)
	) : (
		<NoConnection />
	);
};
Teacher.defaultProps = {
	children: <>No Content</>,
};
Teacher.propTypes = {
	children: element,
};
export default Teacher;
