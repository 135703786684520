import { element } from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Layout from './Layout';
import NoConnection from './NoConnection';
import Waiting from './Waiting';
const Admin = ({ children }) => {
	const { login, status, user } = useSelector(state => state);
	const id = user?.id;
	return status === 'LOADING' ? (
		<Waiting />
	) : status === 'SUCCESS' ? (
		login &&
		(id === 197 || id === 1 || id === 110 || id === 21 || id === 112) ? (
			<Layout>{children}</Layout>
		) : (
			<Navigate to={login ? '/profile' : '/'} replace />
		)
	) : (
		<NoConnection />
	);
};
Admin.defaultProps = {
	children: <>No Content</>,
};
Admin.propTypes = {
	children: element,
};
export default Admin;
