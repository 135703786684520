export const reactToastifyConfiguration = {
	autoClose: 5000,
	closeOnClick: true,
	draggable: true,
	hideProgressBar: false,
	newestOnTop: false,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	position: 'top-right',
	role: 'alert',
	rtl: false,
};
