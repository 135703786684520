import { bool, func, object } from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import ReactSelect from 'react-select';
import styled, { keyframes } from 'styled-components';
const styles = {
	container: provided => ({
		...provided,
		background: 'var(--white)',
		borderRadius: 8,
		height: '100%',
		width: '100%',
	}),
	control: provided => ({
		...provided,
		background: 'var(--light-brown)',
		border: 'none',
		borderRadius: 8,
		boxShadow: 'none',
		flexDirection: 'row-reverse',
		height: '100%',
		outline: 'none',
		padding: 0,
		transition: '400ms',
		width: '100%',
	}),
	menu: provided => ({
		...provided,
		background: 'var(--light-brown)',
		borderRadius: 8,
		boxShadow: '0px 8px 8px rgba(115, 141, 163, 0.2)',
		margin: '8px 0 0 0',
		overflow: 'hidden',
		padding: 0,
	}),
	input: provided => ({
		...provided,
		fontSize: 18,
		fontWeight: 500,
		height: '100%',
		margin: 0,
		padding: 0,
		width: '100%',
	}),
	menuList: provided => ({
		...provided,
		maxHeight: 400,
		padding: 0,
	}),
	valueContainer: provided => ({
		...provided,
		cursor: 'text',
		height: '100%',
		padding: 0,
		width: 'calc(100% - 51px)',
	}),
	placeholder: provided => ({
		...provided,
		fontSize: 18,
	}),
};
const animation = keyframes`
	0% {
		width: 0;
		left: 0;
	}
	50% {
		left: 0;
		width: 100%;
	}
	100% {
		width: 0;
		left: 100%;
	}
`;
const CustomOption = styled.li`
	background: ${({ isFocused }) =>
		isFocused ? 'var(--default)' : 'var(--white)'};
	cursor: pointer;
	display: flex;
	padding: 8px 16px;
	position: relative;
	transition: 0.3s;
	&:hover {
		background: var(--default);
	}
	&:before {
		background: rgba(115, 141, 163, 0.1);
		bottom: 0;
		content: '';
		height: 1px;
		left: 16px;
		position: absolute;
		width: calc(100% - 32px);
	}
	& .search-image {
		height: 64px;
		margin: 0 8px 0 0;
		min-height: 64px;
		min-width: 64px;
		width: 64px;
		& img {
			height: 64px;
			width: 64px;
		}
	}
	& .info {
		& h4 {
			font-size: 16px;
			font-weight: 600;
			margin: 8px 0;
		}
		& h5 {
			font-size: 16px;
			font-weight: 500;
			margin: 0;
		}
	}
`;
const Container = styled.div`
	align-items: center;
	border-radius: 8px;
	display: flex;
	height: 51px;
	margin: 0 24px;
	position: relative;
	width: calc(100% - 48px);
	& .progress {
		animation-delay: 0;
		animation-duration: 1.2s;
		animation-iteration-count: infinite;
		animation-name: ${animation};
		animation-timing-function: ease-in-out;
		background: linear-gradient(
			90deg,
			rgba(0, 0, 255, 0) 0%,
			var(--blue) 20%,
			var(--blue) 80%,
			rgba(0, 0, 255, 0) 100%
		);
		bottom: -4px;
		height: 4px;
		position: absolute;
	}
	@media (max-width: 991px) {
		margin: 0 0 0 24px;
		width: calc(100% - 24px);
	}
	@media (max-width: 414px) {
		display: none;
	}
`;
const SearchButton = styled.button`
	align-items: center;
	background: transparent;
	border: none;
	display: flex;
	height: 100%;
	margin: 0;
	outline: none;
	padding: 0;
	width: 40px;
`;
const IndicatorsContainer = () => (
	<SearchButton>
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
			<circle
				cx='11.7666'
				cy='11.7666'
				r='8.98856'
				stroke='#232B2E'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			/>
			<path
				d='M18.0183 18.4851L21.5423 22'
				stroke='#232B2E'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='1.5'
			/>
		</svg>
	</SearchButton>
);
const Option = ({ innerProps, innerRef, data, isFocused }) => {
	const description = data?.description;
	const image = data?.image;
	const name = data?.name;
	return (
		<CustomOption
			isFocused={isFocused}
			ref={innerRef}
			title={`${name} - ${description}`}
			{...innerProps}
		>
			<div className='search-image'>
				<img src={image} alt='course' />
			</div>
			<div className='info'>
				<h4>{name}</h4>
				<h5>{description}</h5>
			</div>
		</CustomOption>
	);
};
Option.propTypes = {
	data: object,
	innerProps: object.isRequired,
	innerRef: func.isRequired,
	isFocused: bool.isRequired,
};
const Search = () => {
	const [options, setOptions] = useState([]);
	const navigate = useNavigate();
	const [value] = useState({
		isDisabled: true,
		label: '',
		value: '',
	});
	const [name, setName] = useState('');
	const [loading] = useState(false);
	return (
		<Container onClick={e => e.stopPropagation()}>
			{loading && <div className='progress'></div>}
			<ReactSelect
				components={{
					IndicatorsContainer: IndicatorsContainer,
					SingleValue: () => null,
					Option: Option,
					NoOptionsMessage: () => null,
					Placeholder: () => null,
				}}
				filterOption={() => options}
				inputValue={name}
				isSearchable
				onBlur={() => setOptions([])}
				onChange={({ value }) => navigate(`/courses/edit/${value}`)}
				onInputChange={name => setName(name)}
				options={options}
				styles={styles}
				value={value}
			/>
		</Container>
	);
};
export default Search;
